<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <b-row>
                <b-col cols="12" md="3">
                    <b-avatar :variant="variants[user.id % variants.length]" :text="avatarText" size="144px"></b-avatar>
                </b-col>
                <b-col cols="12" md="9" class="mt-4">
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th colspan="2">Datos Generales</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 12%">Usuario: </b-th>
                                <b-td>{{ user.username }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Nombre: </b-th>
                                <b-td>{{ user.name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Email: </b-th>
                                <b-td>{{ user.email }}</b-td>
                            </b-tr>
                      </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'UserIndex' }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button variant="secondary" :to="{ name: 'UserEdit', params: { id: user.id }}" v-if="user.id">
                        <font-awesome-icon icon="edit"></font-awesome-icon> Modificar
                    </b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { userUrl } from '@routes';
import * as constants from '@constants';

export default {
    data() {
        return {
            user: {
                id: 0,
                name: '',
                username: '',
                email: '',
                phone1: '',
                phone2: '',
                password: ''
            },
            breadcrumb: {
                title: 'Usuarios',
                path: [
                    {
                        name: 'UserIndex',
                        text: 'Usuarios'
                    }
                ]
            },
            variants: ['primary', 'success', 'info', 'danger', 'warning'],
            isLoading: false
        };
    },
    created() {
        this.user.id = this.$route.params.id ?? 0;

        this.breadcrumb.path.push({
            name: 'UserShow',
            text: `Detalle`
        });

        this.$emit('update-breadcrumb', this.breadcrumb);

        this.loadData();
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(userUrl(), {
                params: {
                    id: this.$route.params.id,
                    application: this.userType
                }
            }).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    let user = response.data.user;

                    this.user.id = user.id;
                    this.user.name = user.name;
                    this.user.username = user.username;
                    this.user.email = user.email;
                    this.user.phone1 = user.phone1;
                    this.user.phone2 = user.phone2;
                }
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    computed: {
        avatarText() {
            let strings = this.user.name.split(' ');
            let initials = [];

            strings.forEach(string => {
                initials.push(string.slice(0, 1));
            });

            return initials.length > 0 ? initials.join('') : this.username.username.slice(0, 4);
        }
    }
};
</script>

<style scoped>

</style>